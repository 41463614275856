import React, { useContext, useEffect, useState } from "react";
import { GiChickenOven } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import thankyouImg from "../../../assets/img/thankyou.png";
import { store } from "../../../Store/Store";
import PostApiCall from "../helper/PostAPI";
import "./PaymentSuccess.css";
function PaymentSuccess() {
    const navigate = useNavigate();
    const { restaurantName } = useContext(store)
    const [orderType, setOrderType] = useState("")
    useEffect(() => {
        if (JSON.parse(localStorage.getItem("OrderData"))) {
            PostApiCall.postRequest(
                {
                    orderid: JSON.parse(localStorage.getItem("OrderData"))?.order_id,
                },
                "getOrderDetails"
            ).then((results) =>
                results.json().then((obj) => {
                    if (results.status == 200 || results.status == 201) {
                        setOrderType(obj.data[0].fld_order_type)
                        CustomerOrderMailer(obj.data)
                        localStorage.removeItem("OrderData");
                    }
                })
            )
        } else {
            navigate('/');
        }

    }, [])
    const CustomerOrderMailer = (data) => {
        PostApiCall.postRequest(
            {
                ordernumber: data[0]?.fld_order_number,
                customername: data[0]?.Customer_name,
                customeremail: data[0]?.Customer_email,
                totalamount: data[0]?.fld_cart_amount_no_shipping,
                orderData: data,
                deliverycharge: data[0]?.fld_shipping_charge,
                subtotal: data[0]?.fld_cart_amount,
                taxes: data[0]?.fld_total_gst,
                orderdate: data[0]?.fld_order_date, 
                TotalDiscount: data[0]?.fld_total_discount,
                tip: data[0]?.fld_tip,
            },
            "CustomerOrderMailer"
        ).then((result) =>
            result?.json().then((obj3) => {
                if (result.status == 200 || result.status == 201) {
                    navigate('/payment-successful');
                } else {
                    // Notiflix.Notify.Failure("Oops!", "Something went wrong");
                }
            })
        );
    }
    return (
        <div>
            <div className="container-fluid payment-succes-main">
                <div className="row mb-4 align-items-center justify-content-center">
                    <div className="col-md-10 text-center payment-succes-box py-4">
                        <div className="w-100 text-center">
                            <img src={thankyouImg} className="img-fluid payment-succes-icons" />
                            <h3 className="fw-normal">Order Confirmed!</h3>
                        </div>
                        <p>Your payment was successful, and your order is being processed. We're cooking up your delicious meal right now!<GiChickenOven className="mx-2" /> </p>
                        <p>We'll notify you as soon as your order is
                            {orderType == "TakeAway" ? "ready." : "on its way.}"}</p>
                        <p>  Thank you for choosing <b>{restaurantName}</b></p>
                        <div className="text-center w-100 mt-3">
                            <button className="btn btn-danger btn-md" onClick={() => {
                                window.location.href = "/";
                            }}>Continue Ordering</button>
                        </div>
                        {/* <section className="px-3">
                            {
                                orderType ==
                                    "Take Away" ?
                                    <div>
                                        <p className="payment-success-title">Thank you for ordering</p>
                                        <p className="payment-success-title">Your food will be ready in about 20-25 minutes</p>
                                    </div>
                                    :
                                    orderType ==
                                        "Delivery" ?
                                        <div>
                                            <p className="payment-success-title">Thank you for ordering with us.</p>

                                        </div>
                                        : ""
                            }

                        </section> */}

                    </div>
                </div>
            </div>
        </div>
    );
}
export default PaymentSuccess