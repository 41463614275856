import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import {
    Container,
    Nav,
    Navbar,
    Offcanvas,
    ProgressBar,
    Toast
} from "react-bootstrap";
import {
    FaFacebookF,
    FaInstagram
} from "react-icons/fa";
import ReactStars from "react-rating-stars-component";
import { useSearchParams } from "react-router-dom";
import grublogo from "../../../assets/img/logo-grubdigest.png";
// import RestaurantStatus from "../../components/OnlineOrdering/RestaurantStatus/RestaurantStatus";
import PostApiCall from "../../Helpers/PostAPI";
import GetApiCall from "../helper/GetApi";
import "./Navbar.css";
// sidebar icons
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import AmexIcon from "../../../assets/img/payment/amex.svg";
import BHIMUPIIcons from "../../../assets/img/payment/bhim.svg";
import GPayIcon from "../../../assets/img/payment/gpay.svg";
import MastercardIcon from "../../../assets/img/payment/mastercard.svg";
import PaytmIcon from "../../../assets/img/payment/paytm.svg";
import PhonePayIcon from "../../../assets/img/payment/phonepe.svg";
import RupayIcon from "../../../assets/img/payment/rupay.svg";
import UPI from "../../../assets/img/payment/upi.svg";
import VisaIcon from "../../../assets/img/payment/visa.svg";
import { store } from "../../../Store/Store";

function NavbarMenu() {
    const [scroll, setScroll] = useState(false);
    const { restaurantAddress, setRestaurantAddress, setClientLogo } =
        useContext(store);
    const { loginDisabled, setLoginDisabled, clientLogo } = useContext(store);
    const [customerAddress, setCustomerAddress] = useState([]);
    const { restaurantTime, setRestaurantTime } = useContext(store);
    const { bannerDetails, setbannerDetails } = useContext(store);
    const [scrollSticky, setScrollSticky] = useState(false);
    const [clientData, setClientData] = useState([]);
    const { currencyCode, setCurrencyCode, setCustomerValidation } =
        useContext(store);
    const { keyString, setKeyStrings } = useContext(store);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showMenu, setShowMenu] = useState(false);
    const {
        restaurantOpen,
        setRestaurantOpen,
        restaurantName,
        setRestaurantName,
        cardAccepted,
        setcardAccepted,
        setClientDetails
    } = useContext(store);
    const { fssai, setFssai, showLoginModal, setShowLoginModal } =
        useContext(store);
    const [showRating, setShowRating] = useState([]);
    const toggleShowRating = (index) => {
        const newShow = [...showRating];
        newShow[index] = !newShow[index];
        setShowRating(newShow);
    };

    const handleCloseLoginModal = () => setShowLoginModal(false);
    const handleShowLoginModal = () => setShowLoginModal(true);

    const handleCloseMenu = () => setShowMenu(false);
    const handleShowMenu = () => setShowMenu(true);

    useEffect(() => {
        if (loginDisabled == false) {
            getAddress();
        }
        GetApiCall.getRequest("getCardAccepted").then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    setcardAccepted(obj1.message);
                    //localStorage.setItem("BannerDetails", JSON.stringify(obj1.data))
                    Notiflix.Loading.Remove();
                }
            })
        );
    }, []);

    // get address
    const getAddress = async () => {
        var login = localStorage.getItem("LoginDetailsWeb");
        var logindetails = login ? JSON.parse(login)[0] : null;
        PostApiCall.postRequest(
            {
                columns: "*",
                whereClause: ` where fld_customerid = ${localStorage.getItem("LoginDetailsWeb") == null
                    ? null
                    : logindetails?.CustomerId
                    }`,
            },
            "getCustomerAddress"
        ).then((result) =>
            result?.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    setCustomerAddress(obj.message);
                }
            })
        );
    };

    useEffect(() => {
        if (window.pageYOffset > 0) {
            setScroll("scroll");
        } else {
            window.addEventListener("scroll", () => {
                setScroll(window.scrollY > 0);
            });
        }

        if (window.pageYOffset > 0) {
            setScroll("scrollSticky");
        } else {
            window.addEventListener("scrollSticky", () => {
                setScroll(window.scrollY > 0);
            });
        }

        GetApiCall.getRequest("getKeyStrings").then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    setKeyStrings(obj1.data);
                    Notiflix.Loading.Remove();
                }
            })
        );

        GetApiCall.getRequest("getBannerDetails").then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj1.message.length != 0) {
                        setbannerDetails(obj1.message);
                    }
                    Notiflix.Loading.Remove();
                }
            })
        );

        PostApiCall.postRequest(
            {
                id: "",
            },
            "getClientData"
        ).then((results) =>
            results?.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    setClientData(obj1.message);
                    setClientDetails(obj1.message);
                    setRestaurantOpen(obj1.message[0]?.restaurant_open);
                    setFssai(obj1.message[0].fld_fssai_number);
                    setRestaurantAddress(
                        obj1.message[0].fld_address +
                        ", " +
                        obj1.message[0].city_name +
                        ", " +
                        obj1.message[0].state_name
                    );
                    setRestaurantTime(obj1.message[0].res_timings);
                    setRestaurantName(obj1.message[0].fld_restaurantname);
                    setShowRating(Array(obj1.message.length).fill(false));
                    setCurrencyCode(obj1.message[0].fld_currency);
                    setCustomerValidation(obj1.message[0].fld_customer_validation_using);
                    setClientLogo(obj1.message[0]?.fld_image);
                    Notiflix.Loading.Remove();
                }
            })
        );
    }, []);

    return (
        <>
            {/* {restaurantOpen === "false" ? <RestaurantStatus /> : ""} */}
            <section
                // className={
                //   scroll
                //     ? "bg-white shadow-sm mb-0 py-2 fixed-top top-bar pe-0"
                //     : "bg-white shadow-none mb-0 py-2 pe-0"
                // }
                className="bg-white mb-0 py-0 pe-0"
                expand="false">
                {clientData != null ? (
                    clientData.map((cd, index) => {
                        return (
                            <>
                                <div className="container-fluid position-relative px-0 mx-0 z-3">
                                    <Navbar key="md" expand="lg" className="py-md-4 py-0 navabr-bg">
                                        <Container
                                            fluid
                                            className="px-3 py-md-1 py-0 toggle-position-phnview">
                                            <Navbar.Brand
                                                href="/"
                                                className="d-flex align-items-center top-0 logo-position z-3 nav-brand-size mx-md-0 ms-3">
                                                <img
                                                    src={cd.fld_image}
                                                    className="img-fluid logo"
                                                    alt={clientData[0]?.fld_restaurantname}
                                                />
                                                <p className="mb-0 ms-3 fs-2 d-md-flex d-none">{clientData[0]?.fld_companyname}</p>

                                            </Navbar.Brand>
                                            <Navbar.Toggle
                                                aria-controls="offcanvasNavbar-expand-md"
                                                className="text-end bg-white text-dark"
                                            />
                                            <Navbar.Offcanvas
                                                id="offcanvasNavbar-expand-md"
                                                aria-labelledby="offcanvasNavbarLabel-expand-md"
                                                placement="start"
                                                className="phn-view-sidebar">
                                                <Offcanvas.Header closeButton>
                                                    <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
                                                        <img
                                                            src={cd.fld_image}
                                                            className="logo-size"
                                                            alt={clientData[0]?.fld_restaurantname + "Logo"}
                                                        />
                                                    </Offcanvas.Title>
                                                    {clientData != null
                                                        ? clientData.map((cd, index) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        toggleShowRating(index);
                                                                    }}
                                                                    className="d-flex justify-content-start justify-content-lg-end mobile-review-box">
                                                                    <a
                                                                        className="btn-grub w-auto cursor-pointer"
                                                                        onClick={() => {
                                                                            toggleShowRating(index);
                                                                        }}>
                                                                        <p className="rest-rating-reviews mt-2">
                                                                            <p className="mb-1">
                                                                                {cd.rating_count}
                                                                            </p>
                                                                            <p className="mb-0">Reviews</p>
                                                                        </p>
                                                                        <ReactStars
                                                                            count={5}
                                                                            value={cd.FLD_Rating}
                                                                            size={20}
                                                                            edit={false}
                                                                            isHalf={true}
                                                                            activeColor="#ffd700"
                                                                            classNames="cursor-pointer"
                                                                        />
                                                                    </a>
                                                                    <Toast
                                                                        // show={showRating}
                                                                        show={showRating[index]}
                                                                        onClose={() => toggleShowRating(index)}
                                                                        className="rating-popup homepage">
                                                                        <Toast.Header className="justify-content-between">
                                                                            <div className="d-flex flex-column">
                                                                                <div className="d-flex align-items-center gap-2">
                                                                                    <ReactStars
                                                                                        count={5}
                                                                                        value={cd.FLD_Rating}
                                                                                        size={20}
                                                                                        onChange={(rating) => {
                                                                                            var login =
                                                                                                localStorage.getItem(
                                                                                                    "LoginDetailsWeb"
                                                                                                );
                                                                                            var logindetails = login
                                                                                                ? JSON.parse(login)[0]
                                                                                                : "";
                                                                                            if (login === undefined) {
                                                                                                handleShowLoginModal();
                                                                                            } else {
                                                                                                PostApiCall.postRequest(
                                                                                                    {
                                                                                                        rating: rating,
                                                                                                        userId:
                                                                                                            localStorage.getItem(
                                                                                                                "LoginDetailsWeb"
                                                                                                            ) == null
                                                                                                                ? null
                                                                                                                : logindetails.CustomerId,
                                                                                                    },
                                                                                                    "UpdateRestaurantRating"
                                                                                                ).then((result) =>
                                                                                                    result
                                                                                                        ?.json()
                                                                                                        .then((obj) => {
                                                                                                            if (
                                                                                                                result.status ==
                                                                                                                200 ||
                                                                                                                result.status == 201
                                                                                                            ) {
                                                                                                                PostApiCall.postRequest(
                                                                                                                    {
                                                                                                                        id: parseInt(
                                                                                                                            searchParams.get(
                                                                                                                                "clientid"
                                                                                                                            )
                                                                                                                        ),
                                                                                                                    },
                                                                                                                    "getClientData"
                                                                                                                ).then((results) =>
                                                                                                                    results
                                                                                                                        ?.json()
                                                                                                                        .then((obj1) => {
                                                                                                                            if (
                                                                                                                                results.status ==
                                                                                                                                200 ||
                                                                                                                                results.status ==
                                                                                                                                201
                                                                                                                            ) {
                                                                                                                                setClientData(
                                                                                                                                    obj1.message
                                                                                                                                );
                                                                                                                                setClientDetails(obj1.message);
                                                                                                                                setShowRating(
                                                                                                                                    Array(
                                                                                                                                        obj1
                                                                                                                                            .message
                                                                                                                                            .length
                                                                                                                                    ).fill(
                                                                                                                                        false
                                                                                                                                    )
                                                                                                                                );
                                                                                                                                Notiflix.Loading.Remove();
                                                                                                                            }
                                                                                                                        })
                                                                                                                );
                                                                                                                Notiflix.Notify.Success(
                                                                                                                    "Thank you for rating."
                                                                                                                );
                                                                                                            } else {
                                                                                                                Notiflix.Notify.Failure(
                                                                                                                    obj.message
                                                                                                                );
                                                                                                            }
                                                                                                        })
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        edit={true}
                                                                                        isHalf={false}
                                                                                        activeColor="#ffd700"
                                                                                    />
                                                                                    <h6 className="fw-bold d-block mb-0">
                                                                                        {cd.FLD_Rating} out of 5
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </Toast.Header>
                                                                        {cd.rating_breakup
                                                                            ?.split("#")
                                                                            .map((data) => {
                                                                                return (
                                                                                    <Toast.Body className="bg-white">
                                                                                        <div className="d-flex gap-2 align-items-center">
                                                                                            <p className="mb-0 text-end text-dark">
                                                                                                {data?.split(",")[0]} Star
                                                                                            </p>
                                                                                            <div>
                                                                                                <ProgressBar
                                                                                                    variant="warning"
                                                                                                    now={data.split(",")[1]}
                                                                                                />
                                                                                            </div>
                                                                                            <p className="mb-0 text-dark">
                                                                                                {data.split(",")[1]}%
                                                                                            </p>
                                                                                        </div>
                                                                                    </Toast.Body>
                                                                                );
                                                                            })}
                                                                    </Toast>
                                                                </div>
                                                            );
                                                        })
                                                        : ""}
                                                </Offcanvas.Header>
                                                <Offcanvas.Body className="ps-4">
                                                    <Nav className="ps-0 mb-2 justify-content-end flex-grow-1 gap-nav-list navbar-link-color-online-order">
                                                        {/* <Nav.Link href="/">Home</Nav.Link> */}
                                                        {loginDisabled === false ? (
                                                            <Nav.Link
                                                                href="/dashboard"
                                                                className="d-block d-lg-none">
                                                                Dashboard
                                                            </Nav.Link>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {loginDisabled === false ? (
                                                            <Nav.Link
                                                                href="/"
                                                                className="nav-link"
                                                                onClick={() => {
                                                                    localStorage.removeItem("LoginDetailsWeb");
                                                                }}>
                                                                Logout
                                                            </Nav.Link>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Nav>

                                                    <div className="mb-2 mt-5 mb-md-0 d-block d-lg-none">
                                                        <p className="mb-1 mobile-nav-heading">
                                                            Contact us
                                                        </p>
                                                        <a
                                                            href={`tel:${clientData[0]?.fld_phonenumber}`}
                                                            className="mobile-call-icon">
                                                            {" "}
                                                            <IoCall />
                                                            {clientData[0]?.fld_phonenumber}
                                                        </a>
                                                    </div>
                                                    <div className="mb-2 mt-3 mb-md-0 d-block d-lg-none">
                                                        <p className="mb-0 mobile-nav-heading">
                                                            Let's Connect Socially
                                                        </p>
                                                        <ul className="mobile-sm-icon">
                                                            <li>
                                                                <a href={clientData[0]?.fld_facebook}>
                                                                    {/* <i className="fab fa-facebook-f"> */}
                                                                    <FaFacebookF />
                                                                    {/* </i> */}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href={clientData[0]?.fld_instagram}>
                                                                    {/* <i className="fab fa-twitter"> */}
                                                                    <FaInstagram />
                                                                    {/* </i> */}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div
                                                        onClick={() => {
                                                            toggleShowRating(index);
                                                        }}
                                                        className="mt-3 mb-1 mt-lg-0 ms-2 ms-md-4 justify-content-start justify-content-lg-end gap-2 d-none d-lg-flex">
                                                        <a
                                                            className="btn-grub w-auto cursor-pointer"
                                                            onClick={() => {
                                                                toggleShowRating(index);
                                                            }}>
                                                            <p className="rest-rating-reviews">
                                                                <p className="mb-0">{cd.rating_count}</p>
                                                                <p className="mb-0">Reviews</p>
                                                            </p>
                                                            <ReactStars
                                                                count={5}
                                                                value={cd.FLD_Rating}
                                                                size={20}
                                                                edit={false}
                                                                isHalf={true}
                                                                activeColor="#ffd700"
                                                                classNames="cursor-pointer"
                                                            />
                                                        </a>
                                                    </div> */}
                                                </Offcanvas.Body>
                                                <div className="sticky-bottom w-100 pb-4 d-flex flex-column justify-content-center align-items-center d-lg-none p-3">
                                                    <p className="mb-1">Powered by</p>
                                                    <img src={grublogo} className="img-fluid w-25" />
                                                </div>
                                            </Navbar.Offcanvas>
                                        </Container>
                                    </Navbar>
                                </div>
                            </>
                        );
                    })
                ) : (
                    <></>
                )}

                <Offcanvas
                    className="sidebar-phn-cross-btn"
                    show={showMenu}
                    onHide={handleCloseMenu}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            <img
                                src={clientData[0]?.fld_image}
                                className="logo-size-sidebar mx-0 me-4"
                                alt={clientData[0]?.fld_restaurantname}
                            />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="navbar-link-color-sidebar d-block">
                            <Nav.Link className="px-0 mx-0" href="javascript:void(0)">
                                Terms and Conditions
                            </Nav.Link>
                            <Nav.Link className="px-0 mx-0" href="javascript:void(0)">
                                Privacy Policy
                            </Nav.Link>
                            <Nav.Link className="px-0 mx-0" href="javascript:void(0)">
                                FAQ
                            </Nav.Link>
                            <Nav.Link className="px-0 mx-0" href="javascript:void(0)">
                                Disclaimer
                            </Nav.Link>

                            <p className="mt-4">
                                <FaPhone className="sidebar-call-icon" />{" "}
                                {/* <span>(206) 417-1118</span> */}
                            </p>
                            <p className="mt-4 d-flex">
                                <div>
                                    <FaLocationDot className="sidebar-call-icon" />{" "}
                                </div>
                                <div>
                                    {/* <span>
                                        507 NE Northgate Way,
                                        <br /> Seattle, Washington
                                    </span> */}
                                </div>
                            </p>

                            <p className="mb-0 text-lg-left text-left mt-lg-0 mt-md-0 mt-4 border-top py-4">
                                We Accept
                            </p>
                            <ul className="payment-icons list--inline site-footer__icon-list">
                                {/* {cardAccepted != null ? (
                  cardAccepted.map((data, i) => {
                    return (
                      <li
                        className="payment-icon"
                        dangerouslySetInnerHTML={{ __html: data.FLD_Card_Icon }}
                      ></li>
                    );
                  })
                ) : (
                  <div></div>
                )} */}

                                <li className="payment-icon me-3 mb-3">
                                    <img
                                        className="icon icon--full-color sidebar-payment-icon "
                                        src={VisaIcon}
                                    />
                                </li>

                                <li className="payment-icon me-3 mb-3">
                                    <img
                                        className="icon icon--full-color sidebar-payment-icon"
                                        src={MastercardIcon}
                                    />
                                </li>
                                <li className="payment-icon me-3 mb-3">
                                    <img
                                        className="icon icon--full-color sidebar-payment-icon amex-icon-size"
                                        src={AmexIcon}
                                    />
                                </li>
                                <li className="payment-icon me-3 mb-3">
                                    <img
                                        className="icon icon--full-color sidebar-payment-icon"
                                        src={BHIMUPIIcons}></img>
                                </li>

                                <li className="payment-icon me-3 mb-3">
                                    <img
                                        src={UPI}
                                        className="icon icon--full-color sidebar-payment-icon"></img>
                                </li>
                                <li className="payment-icon me-3 mb-3">
                                    <img
                                        className="icon icon--full-color sidebar-payment-icon"
                                        src={RupayIcon}
                                    />
                                </li>
                                <li className="payment-icon me-3 mb-3">
                                    <img
                                        className="icon icon--full-color sidebar-payment-icon"
                                        src={PaytmIcon}
                                    />
                                </li>
                                <li className="payment-icon me-3 mb-3">
                                    <img
                                        className="icon icon--full-color sidebar-payment-icon"
                                        src={GPayIcon}></img>
                                </li>

                                <li className="payment-icon me-3 mb-3">
                                    <img
                                        src={PhonePayIcon}
                                        className="icon icon--full-color sidebar-payment-icon"></img>
                                </li>
                            </ul>

                            <div className="text-lg-end">
                                <a
                                    href="/"
                                    target="blank"
                                    className="text-white onliner-order-desktop btn-lg rest-share-link d-md-block d-none">
                                    Order Online
                                </a>
                            </div>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </section>
        </>
    );
}

export default NavbarMenu;
