import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import {
  FaMinus, FaPepperHot,
  FaPlus
} from "react-icons/fa";
import NonVegetarian from "../../../assets/img/nonveg_icon.png";
import Vegan from "../../../assets/img/vegan_icon.png";
import Vegetarian from "../../../assets/img/veg_icon.png";
import { store } from "../../../Store/Store";
import PostApiCall from "../../Helpers/PostAPI";
function RecipeDetail() {
  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 7,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 4,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 3,
  //   },
  // };
  const { productDetailBanner, productDetailItem, productDetailDescription, setSelectedProperties
    , setSelectedAddons, setShowDetails, itemsToDelete, setSelectedItemId, selectedItemId, setItemsToDelete, currencyCode } = useContext(store);
  //add on group
  const { addOnGroup, setAddOnGroup } = useContext(store);
  const { selectedCartLineId, setSelectedCartLineId } = useContext(store);
  const { currentItemConfig, setCurrentItemConfig } = useContext(store);
  const { showCopyConfig, setShowCopyConfig } = useContext(store);
  const { quantityAdd, setQuantityAdd } = useContext(store);
  const [selectedCount, setSelectedCount] = useState(0);
  const { setcuisines, setallcuisines } = useContext(store);
  const [selectSpiceModal, setSelectSpiceModal] = useState(false);
  const { setCartData } = useContext(store);
  const { itemPrice, setItemPrice } = useContext(store);
  const [selectedAddonCount, setSelectedAddonCount] = useState(0);
  const { setAddOnList } = useContext(store);
  //add property
  const { property, setProperty } = useContext(store);
  const { setPropertyList, propertyList } = useContext(store);
  const { itemId, setItemId } = useContext(store);
  const [itemSpiceLevel, setitemSpiceLevel] = useState("");
  const [spiceLevel, setSpiceLevel] = useState(null)
  // discount
  const [discountAmount, setdiscountAmount] = useState(null);
  // total
  const [totalAmount, settotalAmount] = useState(0.00);

  const { setCartTotal } = useContext(store);
  const { setCartItems } = useContext(store);
  const getCart = async () => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : null;

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails.order_id,
        id:
          localStorage.getItem("LoginDetailsWeb") == null
            ? null
            : logindetails.CustomerId,
        stateid: null,
        itemid: selectedItemId,
        ordertype: sessionStorage.getItem("OrderType")
      },
      "getCartWebsite"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setItemsToDelete(obj.message);
        }
      })
    );
  };

  const getMenuList = () => {
    var Order =
      localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        categoryid: null,
        orderid: localStorage.getItem("OrderData") ==
          null
          ? 0
          : Orderdetails.order_id
      },
      "getFoodMenuOrder"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setcuisines(obj1.message);
          setallcuisines(obj1.message);
          setSelectSpiceModal(false);
          //localStorage.setItem("BannerDetails", JSON.stringify(obj1.message))
          Notiflix.Loading.Remove();
        }
      }))
  }

  return (
    <>
      <section className="offer-banner">
        <Container>
          <Row>

            <Col lg={12}>
              <Card>
                <Card.Body className="p-0">
                  {itemsToDelete.length == 0
                    ? ""
                    : itemsToDelete.map((Cuisine) => {
                      return (
                        <div className="d-flex justify-content-between align-items-start gap-2 mb-3 shadow-sm p-2 rounded">
                          <div className="d-flex align-items-start gap-1">
                            <div>
                              <div className="d-flex align-items-center gap-1 recipe-best">
                                <img
                                  src={
                                    Cuisine.FLD_Food_Type ==
                                      "Vegetarian"
                                      ? Vegetarian
                                      : Cuisine.FLD_Food_Type ==
                                        "NonVegetarian"
                                        ? NonVegetarian
                                        : Vegan
                                  }
                                  className="recipe-type"
                                />
                                <FaPepperHot
                                  className={
                                    Cuisine.spice_level
                                  }
                                />
                                <span
                                  style={{
                                    display:
                                      Cuisine.contains_egg !=
                                        null
                                        ? "flex"
                                        : "none",
                                  }}
                                  className="egg-recipe"
                                >
                                  {Cuisine.contains_egg}
                                </span>
                                <span
                                  style={{
                                    display:
                                      Cuisine.best_seller !=
                                        null
                                        ? "flex"
                                        : "none",
                                  }}
                                  className="best-seller"
                                >
                                  {Cuisine.best_seller}
                                </span>
                              </div>
                              <p className="mb-0 fw-bold">
                                {Cuisine.fld_variantname}
                              </p>
                              <p className="mb-0 small fw-bold d-flex justify-content-start align-items-center">
                                {currencyCode}{" "}
                                {
                                  Cuisine.fld_website_discount_price
                                }
                              </p>
                              {Cuisine.ConfigDetails != "#" ? (
                                <>
                                  <p
                                    className="see-more small fw-bold"
                                  >
                                    Customize
                                    {/* <FaAngleDown /> */}
                                  </p>
                                  {Cuisine.ConfigDetails.split(
                                    "#"
                                  ).map((config) => {
                                    return (
                                      <div className="d-flex justify-content-between align-items-center gap-2">
                                        <div className="d-flex gap-2">
                                          <h6 className="mb-0">
                                            <img
                                              src={
                                                config != null
                                                  ? config.split(
                                                    ","
                                                  )[3] ==
                                                    "Vegetarian"
                                                    ? Vegetarian
                                                    : config.split(
                                                      ","
                                                    )[3] ==
                                                      "NonVegetarian"
                                                      ? NonVegetarian
                                                      : Vegan
                                                  : ""
                                              }
                                              className="recipe-type"
                                            />{" "}
                                            {config != null
                                              ? config.split(
                                                ","
                                              )[1]
                                              : ""}
                                          </h6>
                                        </div>
                                        <div className="text-end">
                                          <p className="mb-0 small fw-bold d-flex justify-content-start align-items-center">
                                            {currencyCode}{" "}
                                            {config != null
                                              ? Number(
                                                config.split(
                                                  ","
                                                )[2]
                                              ).toFixed(2)
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div>
                            <div className="text-end">
                              <div className="mb-0 small fw-bold quantity-control">
                                <Button
                                  className="quantity-btn"
                                  onClick={() => {
                                    setItemId(
                                      Cuisine.FLD_Item_ID
                                    );
                                    setSelectedCartLineId(
                                      Cuisine.fld_cart_dtl_id
                                    );
                                    setCurrentItemConfig(
                                      Cuisine.ConfigDetails
                                    );
                                    var login =
                                      localStorage.getItem(
                                        "LoginDetailsWeb"
                                      );
                                    var logindetails = login ?
                                      JSON.parse(login)[0] : null;
                                    if (
                                      Cuisine.item_count >=
                                      1
                                    ) {
                                      PostApiCall.postRequest(
                                        {
                                          orderdate:
                                            moment().format(
                                              "lll"
                                            ),
                                          itemdetails: `[{"Item":${Cuisine.FLD_Item_ID},"Quantity":${-1},"SpiceLevel":${spiceLevel},"specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No","cartlineid" :"${Cuisine.fld_cart_dtl_id}"}]`,
                                          status: "INCART",
                                          customerid:
                                            localStorage.getItem(
                                              "LoginDetailsWeb"
                                            ) == null
                                              ? 1
                                              : logindetails
                                                .CustomerId,
                                          createdon:
                                            moment().format(
                                              "lll"
                                            ),
                                          updatedon:
                                            moment().format(
                                              "lll"
                                            ),
                                          orderid:
                                            localStorage.getItem(
                                              "OrderData"
                                            ) == null
                                              ? 0
                                              : JSON.parse(
                                                localStorage.getItem(
                                                  "OrderData"
                                                )
                                              ).order_id,
                                          updatedby:
                                            localStorage.getItem(
                                              "LoginDetailsWeb"
                                            ) == null
                                              ? null
                                              : logindetails
                                                .CustomerId,
                                        },
                                        "addToCart"
                                      ).then((result) =>
                                        result
                                          .json()
                                          .then((obj3) => {
                                            if (
                                              result.status ==
                                              200 ||
                                              result.status ==
                                              201
                                            ) {
                                              //setupdateCart(!updateCart);
                                              setSelectedAddons(
                                                []
                                              );
                                              setSelectedProperties(
                                                []
                                              );
                                              if (
                                                localStorage.getItem(
                                                  "OrderData"
                                                ) == null
                                              ) {
                                                localStorage.setItem(
                                                  "OrderData",
                                                  JSON.stringify(
                                                    obj3.message[0]
                                                  )
                                                );
                                              }
                                              if (
                                                parseInt(Cuisine.fld_quantity) == parseInt("1")
                                              ) {

                                                PostApiCall.postRequest(
                                                  {
                                                    cartID:
                                                      JSON.parse(
                                                        localStorage.getItem(
                                                          "OrderData"
                                                        )
                                                      ).order_id,
                                                    customerid:
                                                      localStorage.getItem(
                                                        "LoginDetailsWeb"
                                                      ) == null
                                                        ? 1
                                                        : logindetails.CustomerId,
                                                    cartlineid:
                                                      Cuisine.fld_cart_dtl_id
                                                  },
                                                  "DeleteCartItem"
                                                ).then((result) =>
                                                  result
                                                    .json()
                                                    .then((obj3) => {
                                                      if (
                                                        result.status ==
                                                        200 ||
                                                        result.status ==
                                                        201
                                                      ) {
                                                        //setupdateCart(!updateCart);

                                                        if (
                                                          Cuisine.length ==
                                                          0
                                                        ) {
                                                          localStorage.removeItem(
                                                            "OrderData"
                                                          );
                                                        }
                                                        //getmenulist(record.FLD_Cat_Id);
                                                        getCart();
                                                      } else {
                                                        Notiflix.Notify.Failure(
                                                          obj3.message
                                                        );
                                                      }
                                                    })
                                                );
                                              } else {
                                                getCart();
                                              }
                                            } else {
                                              Notiflix.Notify.Failure(
                                                obj3.message
                                              );
                                            }

                                            // getmenulist(record.FLD_Cat_Id);
                                          })
                                      );

                                      // }



                                    }
                                  }}
                                >
                                  <FaMinus />
                                  {/* this button will be visible when user is not logged in */}
                                </Button>
                                <div className="px-1">
                                  {Cuisine.fld_quantity
                                    .toString()
                                    .padStart(2, 0)}
                                </div>
                                <Button
                                  className="quantity-btn"
                                  onClick={() => {
                                    setItemId(
                                      Cuisine.FLD_Item_ID
                                    );
                                    setCurrentItemConfig(
                                      Cuisine.ConfigDetails
                                    );
                                    setSelectedCartLineId(
                                      Cuisine.fld_cart_dtl_id
                                    );
                                    if (Cuisine.ConfigDetails != "#") {
                                      setShowCopyConfig(true);
                                    } else {
                                      var login =
                                        localStorage.getItem("LoginDetailsWeb");
                                      var logindetails = JSON.parse(login)[0];
                                      PostApiCall.postRequest(
                                        {
                                          orderdate: moment().format("lll"),
                                          itemdetails: `[{"Item":${Cuisine.FLD_Item_ID
                                            },"Quantity":${1},"SpiceLevel":${spiceLevel},"specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No","cartlineid" :"${Cuisine.fld_cart_dtl_id}"}]`,
                                          status: "INCART",
                                          customerid:
                                            localStorage.getItem(
                                              "LoginDetailsWeb"
                                            ) == null
                                              ? null
                                              : logindetails.CustomerId,
                                          createdon: moment().format("lll"),
                                          updatedon: moment().format("lll"),
                                          orderid:
                                            localStorage.getItem(
                                              "OrderData"
                                            ) == null
                                              ? 0
                                              : JSON.parse(
                                                localStorage.getItem(
                                                  "OrderData"
                                                )
                                              ).order_id,
                                          updatedby:
                                            localStorage.getItem(
                                              "LoginDetailsWeb"
                                            ) == null
                                              ? null
                                              : logindetails.CustomerId,

                                          stateid: null,
                                        },
                                        "addToCart"
                                      ).then((result) =>
                                        result?.json().then((obj) => {
                                          if (
                                            result.status == 200 ||
                                            result.status == 201
                                          ) {
                                            localStorage.setItem(
                                              "OrderData",
                                              JSON.stringify(obj.message[0])
                                            );
                                            setSelectedAddons([]);
                                            setSelectedProperties([]);
                                          } else {
                                            Notiflix.Notify.Failure(
                                              obj.message
                                            );
                                          }

                                          getCart();
                                        })
                                      );
                                    }

                                    setQuantityAdd(1);
                                  }}
                                >
                                  <FaPlus />
                                </Button>
                              </div>
                              {Cuisine.netvalue &&
                                <p className="mb-0 small fw-bold d-flex justify-content-end align-items-center">
                                  {currencyCode}{" "}
                                  {Cuisine.netvalue}
                                </p>
                              }
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default RecipeDetail;
